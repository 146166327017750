<template>
  <tr class="">
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link v-if="ticket.status === 'init'" :to="{name: 'TicketEdit', params: {id: ticket.id}}">
        {{ ticket.title }}
      </router-link>
      <router-link v-if="ticket.status !== 'init'" :to="{name: 'TicketDetail', params: {id: ticket.id}}">
        {{ ticket.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ ticket.category }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <span
          :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getTicketStatusClass(ticket.status)">{{
          getTicketStatusText(ticket.status)
        }}</span>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(ticket.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <div class="">
        <router-link class="mr-2" :to="{name: 'TicketDetail', params: {id: ticket.id}}">Xem</router-link>
        <router-link v-if="ticket.status === 'init'" :to="{name: 'TicketEdit', params: {id: ticket.id}}">Sửa
        </router-link>
        <button v-on:click="deleteTicket(ticket)" v-if="ticket.status === 'init'"
                class="inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-purple-100 focus:shadow-outline hover:bg-purple-200 rounded-lg">
          Xóa
        </button>
      </div>
    </td>
  </tr>
</template>

<script>

import {
  getTicketStatusText,
  getTicketStatusClass,
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminTicketItem",
  props: {
    ticket: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    getTicketStatusText(x) {
      return getTicketStatusText(x);
    },
    getTicketStatusClass(x) {
      return getTicketStatusClass(x);
    },
    deleteTicket(ticket) {
      this.$emit("deleteTicket", ticket);
    },
  },
  created() {
    this.isChecked = this.ticket.is_checked;
  },
  watch: {
    "ticket.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedTicket", this.ticket, newVal);
      }
    },
  }
}
</script>
