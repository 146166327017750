<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <div class="lg:mt-8 mt-2">
            <table class="w-full border-collapse">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="py-2 pr-2">Tiêu đề</th>
                <th class="py-2 pr-2">Chuyên mục</th>
                <th class="py-2 pr-2">Trạng thái</th>
                <th class="py-2 pr-2">Ngày tạo</th>
                <th class="py-2 pr-2">Thao tác</th>
              </tr>
              </thead>
              <tbody class="divide-y" v-if="tickets.data && tickets.data.length">
              <ticket-item v-on:deleteTicket="deleteTicket" unique="tickets" :ticket="ticket" v-on:handleCheckedTicket="handleCheckedTicket"
                          v-for="(ticket, index) in tickets.data" :key="'tickets' + index"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="tickets.paginatorInfo.lastPage > 1"
                  v-model="tickets.paginatorInfo.currentPage"
                  :pages="tickets.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadAllTickets"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import TicketItem from "./TicketItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {changePageTitle} from "../../../core/services/utils.service";

export default {
  name: "TicketList",
  components: {TicketItem, VPagination},
  data() {
    return {
      q: "",
      action: "",
      isCheckedAll: false,
      tickets: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      selectedTicketIds: [],
      currentTicket: null,
      reason: ""
    }
  },
  methods: {
    loadAllTickets() {
      let where = {
        AND: []
      }

      if (this.q) {
        where.AND.push({column: "title", value: this.q + "%", operator: "LIKE"});
      }

      let query = `query($page: Int, $where: WhereConditions) {
        tickets(first:10, where: $where, page: $page, orderBy: [{column: "created_at", order:DESC}]) {
          data {
            id
            title
            status
            category
            created_at
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.tickets.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.tickets) {
              this.tickets = data.data.tickets;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    deleteTicket(ticket) {
      this.currentTicket = ticket;

      if (confirm("Bạn có chắc muốn xóa ticket này?")) {
        let query = `mutation($id: ID!) {
          deleteTicket(id: $id) {
            id
          }
        }`;

        ApiService.graphql(query, {id: this.currentTicket.id})
            .then(({data}) => {
              if (data.data && data.data.deleteTicket) {
                this.loadAllTickets();
                this.$toast.success("Xóa ticket thành công");
              } else {
                this.$toast.error(data.errors[0].message);
              }
            })
            .catch((response) => {
              this.$toast.error(response.message);
            });
      }
    },
    handleCheckedTicket(ticket, isChecked) {
      this.tickets.data.map((item) => {
        if (item.id === ticket.id) {
          item.is_checked = isChecked;
          return false;
        }
      })
    },
    reRender() {
      this.isCheckedAll = false;
      this.selectedTicketIds = [];
      this.loadAllTickets();
    }
  },
  mounted() {
    this.reRender();
    changePageTitle("Quản lý ticket");
  },
  watch: {
    q: {
      handler() {
        this.loadAllTickets();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.tickets.data.map((ticket) => {
          ticket.is_checked = newVal;
        });
      }
    },
    "tickets.data": {
      handler(newVal) {
        this.selectedTicketIds = [];
        newVal.map((ticket) => {
          if (ticket.is_checked) {
            this.selectedTicketIds.push(ticket.id);
          }
        });
      },
      deep: true
    }
  }
}
</script>
